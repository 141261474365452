import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';

import NuDSFormBR from 'components/NuDSFormBR/NuDSFormBR';
import { getProspectType, MULTI_PRODUCT } from 'utils/prospectTypes';
import {
  trackShortFormSubmission,
  trackFieldFilling,
} from 'tracking/application/form';
import { useApplicationFormDrawerContext } from 'patterns/ApplicationFormDrawer/ApplicationFormContext';

import { getFieldProperties, normalizeFieldValidations } from './utils';

function ShortForm({
  formSubmitButtonLabel,
  formTitle,
  initialField,
  propsFormBox,
  propsFormButton,
  prospectTypeKey,
}) {
  const { formatMessage } = useIntl();
  const { openDrawer } = useApplicationFormDrawerContext();
  const uppercaseInitialField = initialField.toUpperCase();
  const fieldProps = getFieldProperties(initialField);
  const prospectType = getProspectType(prospectTypeKey);

  const sendTrackingEvents = () => {
    trackShortFormSubmission({ prospectType: prospectType.type });
  };

  const handleSubmit = async ({ values, setSubmitting }) => {
    sendTrackingEvents();
    openDrawer({
      initialField,
      initialFieldValue: values[initialField],
    });
    setSubmitting(false);
  };

  const handleOnBlur = event => {
    if (event.target.value) {
      trackFieldFilling({
        prospectType: prospectType.type,
        field: `${initialField}_short`,
      });
    }
  };

  return (
    <>
      <NuDSFormBR>
        <NuDSFormBR.Step
          noValidate
          onSubmit={handleSubmit}
          initialValues={{
            [initialField]: '',
          }}
          enableReinitialize
        >
          {({ isDisabled, isSubmitting }) => (
            <Box id="short-form" marginRight={{ md: 0 }} {...propsFormBox}>
              <Typography
                tag="h3"
                variant="heading4"
                color="black.default"
                marginBottom="8x"
                intlKey={formTitle}
              />
              <TextField
                id={`field-${initialField}`}
                name={initialField}
                type={fieldProps.type}
                label={formatMessage({
                  id: `COMMON.APPLICATION.LONG_LABEL.${uppercaseInitialField}`,
                })}
                syncValidations={normalizeFieldValidations(
                  fieldProps.validations,
                  formatMessage,
                )}
                onBlur={handleOnBlur}
              />
              <Box {...propsFormButton}>
                <Button
                  id="test"
                  type="submit"
                  variant="contained"
                  styleVariant="primary"
                  extended
                  disabled={typeof window === 'undefined' || isDisabled || isSubmitting}
                  intlKey={formSubmitButtonLabel}
                  iconProps={{ name: 'arrow-right' }}
                />
              </Box>
            </Box>
          )}
        </NuDSFormBR.Step>
      </NuDSFormBR>
    </>
  );
}

ShortForm.defaultProps = {
  formSubmitButtonLabel: 'COMMON.WIDGET.SHORT_FORM.CONTINUE_BUTTON',
  formTitle: 'SHORT_FORM.MULTI_PRODUCT.FORM_TITLE',
  initialField: 'cpf',
  propsFormBox: {
    backgroundColor: 'white.default',
    borderRadius: '1rem',
    margin: 'auto',
    maxWidth: '20.625rem',
    padding: '1.5rem',
    position: 'relative',
  },
  propsFormButton: {
    marginHorizontal: '0',
    maxWidth: '100%',
  },
  prospectTypeKey: MULTI_PRODUCT,
};

ShortForm.propTypes = {
  formSubmitButtonLabel: PropTypes.string,
  formTitle: PropTypes.string,
  initialField: PropTypes.oneOf(['email', 'name', 'cpf', 'cnpj']),
  propsFormBox: PropTypes.objectOf(PropTypes.shape),
  propsFormButton: PropTypes.objectOf(PropTypes.shape),
  prospectTypeKey: PropTypes.string,
};

export default React.memo(ShortForm);
