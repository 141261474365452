import React, { useContext } from 'react';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionProducts({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const cmsPrefix = `cms.requestNuPage.cardsArea.${index}.productCards`;

  return (
    <Box
      tag="section"
      className="SectionProducts"
      backgroundColor="white.dark"
    >
      <Grid
        paddingLeft={{ xs: '0x', md: '28x' }}
        paddingRight={{ xs: '0x', md: '28x' }}
        paddingTop={{ md: '18x' }}
        paddingBottom={{ md: '18x' }}
      >
        <Grid.Child gridColumn={{ xs: '1 / -1' }}>
          <Box display={{ lg: 'flex' }}>
            <Box
              display="grid"
              gridRowGap="8x"
              paddingHorizontal={{ xs: '6x', md: '8x' }}
              paddingVertical={{ xs: '12x', md: '12x' }}
              backgroundColor="black.default"
            >
              <Typography
                textAlign="left"
                variant="heading3"
                tag="p"
                color="white"
                colorVariant="default"
                intlKey={`${cmsPrefix}[0].title`}
                maxWidth={{ md: '200px' }}
              />
              <Box display="flex" justifyContent="center">
                <Image
                  maxWidth={{ md: '340px', lg: '400px' }}
                  loading="lazy"
                  altIntlKey={`${cmsPrefix}[0].image[0].imageDesktop.alt`}
                  srcSet={generateDPRsForCMSImage(formatMessage({ id: `${cmsPrefix}[0].image` }))}
                />
              </Box>
              <Typography
                variant="subtitle1"
                tag="p"
                strong
                color="white"
                colorVariant="default"
                intlKey={`${cmsPrefix}[0].description`}
              />
            </Box>
            <Box
              textAlign="left"
              display="grid"
              gridRowGap="6x"
              paddingHorizontal={{ xs: '6x', md: '8x' }}
              paddingVertical={{ xs: '12x', md: '12x' }}
              backgroundColor={{ xs: 'white.dark', md: 'white.default' }}
            >
              <Typography
                intlKey={`${cmsPrefix}[1].title`}
                variant="heading3"
                tag="p"
                maxWidth={{ md: '200px' }}
              />
              <Box display="flex" justifyContent="center">
                <Image
                  maxWidth={{ md: '340px' }}
                  loading="lazy"
                  altIntlKey={`${cmsPrefix}[1].image[0].imageDesktop.alt`}
                  srcSet={generateDPRsForCMSImage(formatMessage({ id: `${cmsPrefix}[1].image` }))}
                />
              </Box>
              <Typography
                variant="subtitle1"
                strong
                intlKey={`${cmsPrefix}[1].description`}
              />
            </Box>
          </Box>
        </Grid.Child>
      </Grid>
    </Box>
  );
}

SectionProducts.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionProducts);
