import styled from 'styled-components';
import { spacing } from '@nubank/nuds-web/styles/themeUtils';
import Icon from '@nubank/nuds-web/components/Icon/Icon';

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${spacing('2x')};
  margin-top: ${spacing('1x')};
`;

StyledIcon.displayName = 'StyledIcon';
