import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const StyledTypography = styled(Typography)`
  ${breakpointsMedia({
    md: css`
      font-size: 2rem;
      line-height: 1.16;
   `,
    lg: css`
      font-size: 3rem;
   `,
  })}
`;
