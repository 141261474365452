import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionPress({ index }) {
  const cmsPrefix = `cms.requestNuPage.cardsArea.${index}`;
  const { formatMessage } = useContext(NuDsContext);
  const newsItems = formatMessage({ id: `${cmsPrefix}.newsCards` });

  return (
    <Box
      className="SectionPress"
      tag="section"
      display="flex"
      alignItems={{ lg: 'center' }}
      paddingVertical={{ xs: '12x', md: '20x', lg: '24x' }}
      backgroundColor="white.default"
    >
      <Grid rowGap={{ xs: '16x', md: '20x', lg: '40x' }}>
        <Grid.Child
          gridColumn={{ xs: '1 / -1', md: 'span 4', lg: '2 / span 4' }}
        >
          <Typography
            variant="heading3"
            intlKey={`${cmsPrefix}.title`}
          />
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / -1', lg: '2 / span 10' }}
          gridRow={{ lg: '2' }}
        >
          <Box display={{ md: 'flex' }} justifyContent={{ md: 'space-between' }}>
            {newsItems.map((item, itemIndex) => {
              const imgSrcSet = generateDPRsForCMSImage([{
                imageDesktop: {
                  url: item.logo.url,
                },
              }]);
              return (
                <Box
                  key={`${item}--card`}
                  display="flex"
                  flexDirection="column"
                  paddingBottom={{ xs: itemIndex === 2 ? '0' : '12x', md: '0' }}
                  paddingRight={{ md: itemIndex === 2 ? '0' : '20x', lg: itemIndex === 2 ? '0' : '28x' }}
                >
                  <Typography
                    variant="subtitle1"
                    strong
                    tag="p"
                    marginBottom="4x"
                    maxWidth={{ lg: '230px' }}
                  >
                    {item.headline}
                  </Typography>
                  <Image
                    altIntlKey={`${cmsPrefix}.newsCards.${itemIndex}.logo.alt`}
                    srcSet={imgSrcSet}
                    aria-hidden="true"
                    maxWidth="70px"
                  />
                  <Link
                    href={item.ctaLink}
                    variant="action"
                    target="_blank"
                    rel="noreferrer"
                    typographyProps={{
                      marginTop: '4x',
                    }}
                  >
                    {item.ctaText}
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Grid.Child>
      </Grid>
    </Box>
  );
}

SectionPress.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SectionPress;
