import { useEffect, useState } from 'react';

import useInView from '@nubank/www-latam-commons/utils/hooks/useInView';

const DEFAULT_OPTIONS = {
  intersectionOptions: {
    triggerOnce: false,
    threshold: 0.1,
  },
};

export default function useOpenFormVisibility(
  options = DEFAULT_OPTIONS,
  useInViewHook = useInView,
) {
  const [isOpenFormHidden, setOpenFormHidden] = useState(false);
  const [ref, isElementVisible, entry] = useInViewHook(options);

  useEffect(() => {
    setOpenFormHidden(Boolean(!isElementVisible && entry));
  }, [isElementVisible, entry]);

  return [ref, isOpenFormHidden, entry];
}
