import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
import LazyBgBox from 'components/LazyBgBox/LazyBgBox';

function SectionComplexity({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const cmsPrefix = `cms.requestNuPage.cardsArea.${index}`;
  const imgSrcSet = generateDPRsForCMSImage(formatMessage({ id: `${cmsPrefix}.image` }));
  const optimizedImageSrcSet = {
    xs: imgSrcSet.xs,
    md: imgSrcSet.md,
    lg: {
      '1x': `${imgSrcSet.lg['1x']}?w=1920`,
      '2x': `${imgSrcSet.lg['2x']}?w=1920`,
      '3x': `${imgSrcSet.lg['3x']}?w=1920`,
    },
  };

  return (
    <LazyBgBox
      className="SectionComplexity"
      tag="section"
      display="flex"
      minHeight={{ xs: 'calc(100vh - 25rem)', md: 'calc(100vh - 50rem)', lg: 'calc(100vh - 10rem)' }}
      backgroundImage={optimizedImageSrcSet}
      backgroundSize="cover"
      backgroundPosition="center center"
      paddingVertical={{ xs: '8x', md: '16x' }}
    >
      <Grid alignItems={{ lg: 'center' }}>
        <Grid.Child gridColumn={{ xs: 'span 3', lg: '2 / span 4' }}>
          <Typography
            variant="heading2"
            tag="p"
            color="white"
            marginBottom="4x"
            intlKey={`${cmsPrefix}.title`}
          />
          <Typography
            variant="heading4"
            strong
            tag="p"
            color="white"
            intlKey={`${cmsPrefix}.description`}
            maxWidth={{ xs: '200px', md: '250px', lg: '300px' }}
          />
        </Grid.Child>
      </Grid>
    </LazyBgBox>
  );
}

SectionComplexity.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionComplexity);
