import React, { useContext } from 'react';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
import LazyBgBox from 'components/LazyBgBox/LazyBgBox';

function SectionPossibilities({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const imgSrcSet = generateDPRsForCMSImage(formatMessage({ id: `cms.requestNuPage.cardsArea[${index}].imageArea` }));
  const optimizedImageSrcSet = {
    xs: imgSrcSet.xs,
    md: imgSrcSet.md,
    lg: imgSrcSet.lg,
  };

  return (
    <LazyBgBox
      id="section-company"
      className="SectionPossibilities"
      tag="section"
      display="flex"
      minHeight={{ xs: '53vh', md: '35vh', lg: 'calc(100vh - 3.5rem)' }}
      backgroundColor="#DFD8D2"
      backgroundImage={optimizedImageSrcSet}
      backgroundSize={{ xs: 'cover' }}
      backgroundRepeat="no-repeat"
      backgroundPosition="center right"
      paddingVertical="12x"
    >
      <Grid>
        <Grid.Child gridColumn={{ xs: '1 / span 4', md: '4 / span 3', lg: '7 / span 6' }}>
          <Typography
            variant="heading2"
            tag="p"
            whiteSpace="pre-line"
            intlKey={`cms.requestNuPage.cardsArea.${index}.title`}
            color={{ xs: 'white.default' }}
          />
        </Grid.Child>
      </Grid>
    </LazyBgBox>
  );
}

SectionPossibilities.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SectionPossibilities;
