import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionNoBureaucracy({ index }) {
  const cmsPrefix = `cms.requestNuPage.cardsArea.${index}`;
  const { formatMessage } = useContext(NuDsContext);
  const imgSrcSet = generateDPRsForCMSImage([{
    imageDesktop: {
      url: formatMessage({ id: `${cmsPrefix}.illustration.url` }),
    },
    imageMobile: {
      url: formatMessage({ id: `${cmsPrefix}.illustration.url` }),
    },
    imageTablet: {
      url: formatMessage({ id: `${cmsPrefix}.illustration.url` }),
    },
  }]);

  return (
    <Box
      className="SectionNoBureaucracy"
      tag="section"
      display="flex"
      minHeight={{ lg: 'calc(100vh - 10rem)' }}
      paddingVertical={{ xs: '16x', lg: '24x' }}
      backgroundColor="white.dark"
    >
      <Grid alignItems={{ md: 'center' }}>
        <Grid.Child gridColumn={{ xs: '1 / -1', md: 'span 3', lg: '2 /span 4' }}>
          <Typography
            variant="heading2"
            marginBottom="8x"
            intlKey={`${cmsPrefix}.title`}
          />
          <Typography
            variant="heading4"
            marginBottom="6x"
            intlKey={`${cmsPrefix}.description`}
          />

        </Grid.Child>
        <Grid.Child gridColumn={{ xs: '1 / -1', md: 'span 3', lg: '7 / span 5' }}>
          <Image
            loading="lazy"
            srcSet={imgSrcSet}
            altIntlKey={`${cmsPrefix}.illustration.alt`}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

SectionNoBureaucracy.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SectionNoBureaucracy;
