import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import useInView from '@nubank/www-latam-commons/utils/hooks/useInView';

function LazyBgBox({ children, backgroundImage, ...props }) {
  const [ref, isVisible] = useInView();
  const [lazyBg, setLazyBg] = useState({});

  useEffect(() => {
    if (isVisible) {
      setLazyBg(backgroundImage);
    }
  }, [isVisible]);
  return (
    <Box
      {...props}
      ref={ref}
      backgroundImage={lazyBg}
    >
      {children}
    </Box>
  );
}

LazyBgBox.propTypes = {
  backgroundImage: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number, PropTypes.object],
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export default LazyBgBox;
