import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

import { StyledIcon } from './styles/StyledIcon';
import { StyledTypography } from './styles/StyledTypography';

function SectionAllYouNeed({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const cmsPrefix = `cms.requestNuPage.cardsArea.${index}`;
  const imgSrcSet = generateDPRsForCMSImage(formatMessage({ id: `${cmsPrefix}.image` }));
  const TOPICS = formatMessage({ id: `${cmsPrefix}.topics` });
  const isScreenLg = useBreakpointsMediaUp('md');
  const optimizedSrcSet = {
    xs: {
      '1x': `${imgSrcSet.xs['1x']}?q=10`,
      '2x': `${imgSrcSet.xs['2x']}?q=10`,
      '3x': `${imgSrcSet.xs['3x']}?q=10`,
    },
    md: {
      '1x': `${imgSrcSet.md['1x']}?q=10`,
      '2x': `${imgSrcSet.md['2x']}?q=10`,
      '3x': `${imgSrcSet.md['3x']}?q=10`,
    },
    lg: {
      '1x': `${imgSrcSet.lg['1x']}?q=10`,
      '2x': `${imgSrcSet.lg['2x']}?q=10`,
      '3x': `${imgSrcSet.lg['3x']}?q=10`,
    },
  };

  return (
    <Box
      className="SectionAllYouNeed"
      tag="section"
      display="flex"
      minHeight={{ lg: 'calc(100vh - 5rem)' }}
      paddingVertical="12x"
    >
      <Grid alignItems={{ md: 'start', lg: 'center' }}>
        <Grid.Child gridColumn={{ xs: '1 / -1', md: '1 / span 3', lg: '2 / span 4' }}>
          <Image
            loading="lazy"
            srcSet={optimizedSrcSet}
            altIntlKey={`${cmsPrefix}.image[0].imageDesktop.alt`}
          />
        </Grid.Child>
        <Grid.Child gridColumn={{ xs: '1 / -1', md: '4 / span 3', lg: '7 / span 5' }}>
          <StyledTypography
            variant="heading3"
            tag="p"
            marginBottom="6x"
            marginTop={{ xs: '6x', md: '0' }}
            intlKey={`${cmsPrefix}.title`}
          />
          <ul>
            {TOPICS.map(topic => (
              <Box
                tag="li"
                paddingBottom="1x"
                display="flex"
                key={topic.id}
              >
                <StyledIcon name="arrow-down-right" ariaHidden="true" />
                <Typography
                  tag="p"
                  strong
                  variant={isScreenLg ? 'heading4' : 'subtitle1'}
                >
                  {topic.text}
                </Typography>
              </Box>
            ))}
          </ul>
          <Typography
            variant={isScreenLg ? 'heading4' : 'subtitle1'}
            strong
            tag="p"
            marginBottom="6x"
            marginTop="6x"
            intlKey={`${cmsPrefix}.description`}
          />
          <Link
            href={formatMessage({ id: `${cmsPrefix}.ctaLink` })}
            variant="action"
            intlKey={`${cmsPrefix}.ctaText`}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

SectionAllYouNeed.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionAllYouNeed);
